<template>
  <el-card class="shop-info">
    <div slot="header">
      <div class="title fz-18">编辑店铺资料</div>
    </div>
    <el-form :rules="shopInfoFormRules" ref="shopInfoForm" :model="shopInfoForm"
             class="shopInfoForm" label-width="100px">
      <el-form-item prop="name" label="店铺名称" class="">
        <el-input v-model.trim="shopInfoForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="logo" label="店铺Logo" class="auto">
        <el-upload
          :http-request="fileUploadFunction"
          :data="{...uploadEnum.SHOP}"
          action=""
          :show-file-list="false"
          :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'logo'})"
          :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'logo'})">
          <img class="logo" alt v-if="preview.logoFilePath" :src="preview.logoFilePath">
          <el-button v-else size="small" type="primary">选取文件</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，大小限制512K，建议480X480PX</div>
        </el-upload>
      </el-form-item>
      <el-form-item prop="mainBusiness" label="主营业务" class="auto">
        <el-input
          type="textarea"
          :rows="5"
          maxlength="255"
          placeholder="请输入主营业务"
          v-model="shopInfoForm.mainBusiness">
        </el-input>
        <div class="" style="line-height: 2;">
          快捷输入：
          <a v-for="item in condition.data.business">
            <el-tag
              @click="condition.onBusinessTagClick(item)"
              class="mg-r5"
              type="info" size="mini">
              {{ item }}
            </el-tag>
          </a>
        </div>
      </el-form-item>
      <el-form-item prop="principal" label="店铺负责人" class="">
        <el-input v-model.trim="shopInfoForm.principal"></el-input>
      </el-form-item>
      <el-form-item prop="phone" label="联系电话" class="">
        <el-input type="tel" readonly v-model.trim="shopInfoForm.phone">
          <el-button
            @click="changePhoneDialogConfig.showChangePhoneDialog = true"
            slot="append">更换手机号
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item class="" label="QQ号码" prop="qqNumber">
        <el-input :readonly="shopInfoForm.qqAuth === 1"
                  v-model.trim="shopInfoForm.qqNumber"></el-input>
        <div class="auth_tips_wrap">
          <template v-if="shopInfoForm.qqAuth === 0">
            <i class="el-icon-info fz-20"></i><span>待验证</span>
          </template>
          <template v-if="shopInfoForm.qqAuth === 1">
            <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
          </template>
          <template v-if="shopInfoForm.qqAuth === 2">
            <i class="el-icon-error fz-20" style="color: #F56C6C;"></i><span>验证未通过，请重新输入QQ号</span>
          </template>
          <template v-if="shopInfoForm.qqAuth === 3">
            <i class="el-icon-warning fz-20"></i><span>待用户接收QQ邮箱验证</span>
          </template>
        </div>
      </el-form-item>
      <el-form-item class="" label="微信号码" prop="wxNumber">
        <el-input :readonly="shopInfoForm.wxAuth === 1"
                  v-model.trim="shopInfoForm.wxNumber" maxlength="20">
          <template slot="append" v-if="shopInfoForm.wxAuth !== 1">
            <a @click="shopInfoForm.wxNumber = shopInfoForm.phone"><i
              class="el-icon-thumb mg-r5"></i>手机同号</a>
          </template>
        </el-input>
        <div class="auth_tips_wrap">
          <template v-if="shopInfoForm.wxAuth === 0">
            <i class="el-icon-info fz-20"></i><span>待验证</span>
          </template>
          <template v-if="shopInfoForm.wxAuth === 1">
            <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
          </template>
          <template v-if="shopInfoForm.wxAuth === 2">
            <i class="el-icon-error fz-20"
               style="color: #F56C6C;"></i><span>{{ shopInfoForm.wxAuthMemo }}</span>
          </template>
        </div>
      </el-form-item>
      <el-form-item class="auto" label="微信二维码" prop="wxQrcode">
        <el-upload
          :http-request="fileUploadFunction"
          :disabled="shopInfoForm.wxQrcodeAuth === 1"
          :data="{...uploadEnum.USER}"
          action=""
          :show-file-list="false"
          :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'wxQrcode'})"
          :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'wxQrcode'})">
          <div
            v-if="preview.wxQrcode"
            style="width: 150px; height: 150px;position: relative"
          >
            <el-image
              :src="preview.wxQrcode"
              style="width: 150px; height: 150px"
              fit="cover"
            ></el-image>
            <div class="auth_tips_wrap">
              <template v-if="shopInfoForm.wxQrcodeAuth === 0">
                <i class="el-icon-info fz-20"></i><span>待验证</span>
              </template>
              <template v-if="shopInfoForm.wxQrcodeAuth === 1">
                <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
              </template>
              <template v-if="shopInfoForm.wxQrcodeAuth === 2">
                <i class="el-icon-error fz-20"
                   style="color: #F56C6C;"></i><span>{{ shopInfoForm.wxAuthMemo }}</span>
              </template>
            </div>
          </div>
          <el-button v-else size="small" type="primary">选取文件</el-button>
          <div slot="tip" class="el-upload__tip">注：只能上传jpg/png文件，文件大小1M内，建议格式300X300px</div>
        </el-upload>
      </el-form-item>
      <el-form-item prop="area" label="经营地址" class="">
        <el-cascader
          style="width: 100%;"
          :options="area"
          v-model="shopInfoForm.area"></el-cascader>
      </el-form-item>
      <el-form-item prop="addressDetail" label="详细地址" class="">
        <el-input v-model.trim="shopInfoForm.addressDetail"></el-input>
      </el-form-item>
      <el-form-item class="auto" prop="introduce" label="店铺介绍">
        <ckeditor v-model="shopInfoForm.introduce"></ckeditor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('shopInfoForm')">提交认证</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      title="更换手机号"
      :visible.sync="changePhoneDialogConfig.showChangePhoneDialog"
      width="25%"
      destroy-on-close
      center>
      <el-form
        :ref="changePhoneDialogConfig.formRefName"
        :model="changePhoneDialogConfig.form"
        :rules="changePhoneDialogConfig.formRules"
      >
        <el-form-item prop="phone">
          <el-input
            placeholder="请输入手机号" v-model="changePhoneDialogConfig.form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            placeholder="请输入验证码" v-model="changePhoneDialogConfig.form.code"
          >
            <el-button
              :loading="!changePhoneDialogConfig.canClickSendBtn"
              @click="changePhoneDialogConfig.onSendCodeClick" slot="append"
            >
              <template v-if="changePhoneDialogConfig.canClickSendBtn">
                {{ changePhoneDialogConfig.sendCodeTipsText }}
              </template>
              <count-down v-else @finish="changePhoneDialogConfig.onCountDownFinish" format="ss秒后重试"
                          :time="60 * 1000"></count-down>
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary" @click="changePhoneDialogConfig.onSubmitClick"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="captcha-dialog" center
      :visible.sync="changePhoneDialogConfig.showCaptchaDialog">
      <div class="captcha" ref="captcha"></div>
    </el-dialog>
  </el-card>
</template>

<script>

import area from '@/resources/js/area'
import asyncValidator from '@/resources/js/async-validator'
import validator from "@/resources/js/validator";
import uploadEnum from "@/resources/js/uploadenum";
import {captcha, shop} from '@/apis/index'

const config = require('../../resources/js/config');
require('@ASSET/libs/jigsaw/jigsaw.min')
export default {
  name: 'ShopInfoUpdate',
  components: {
    CountDown: () => import('@CMP/common/CountDown'),
    Ckeditor: () => import('@CMP/ckeditor/Index'),
  },
  data() {
    return {
      changePhoneDialogConfig: {
        formRefName: 'changePhoneForm',
        showChangePhoneDialog: false,
        sendCodeTipsText: '获取验证码',
        canClickSendBtn: true,
        showCaptchaDialog: false,
        form: {
          phone: null,
          code: null
        },
        formRules: {
          phone: [
            {required: true, message: '请输入手机号', trigger: ['blur', 'change']},
            {validator: asyncValidator.validPhone, message: '手机号格式不正确', trigger: ['blur', 'change']}
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: ['blur', 'change']},
            {length: 6, message: '请输入六位', trigger: ['blur', 'change']},
          ]
        },
        onSendCodeClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validateField('phone', res => {
            if (!res) {
              this.changePhoneDialogConfig.initCaptcha({
                onSuccess: this.changePhoneDialogConfig.doSendCode
              });
              return;
            }
            this.changePhoneDialogConfig.canClickSendBtn = true
          })
        },
        initCaptcha: ({onSuccess}) => {
          if (!this.changePhoneDialogConfig.showCaptchaDialog) {
            this.changePhoneDialogConfig.showCaptchaDialog = true;
          }
          this.$nextTick(() => {
            this.$refs.captcha.innerHTML = '';
            window.jigsaw.init({
              el: this.$refs.captcha,
              onSuccess: () => {
                this.changePhoneDialogConfig.showCaptchaDialog = false;
                if (onSuccess) {
                  onSuccess();
                }
              },
              onFail: () => {
              },
              onRefresh: () => {
              }
            })
          });
        },
        doSendCode: () => {
          this.changePhoneDialogConfig.canClickSendBtn = false;
          captcha.sendPhoneCode({
            phone: this.changePhoneDialogConfig.form.phone
          }).then(res => {
            this.$message.success('发送成功，请注意查收!')
          }).catch(res => {
            this.changePhoneDialogConfig.canClickSendBtn = true;
          })
        },
        onSubmitClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validate(res => {
            if (res) {
              this.changePhoneDialogConfig.doCheckCode();
            }
          })
        },
        doCheckCode: () => {
          const {phone, code} = this.changePhoneDialogConfig.form;
          captcha.checkPhoneCode({
            phone: phone,
            code: code
          }).then(res => {
            this.shopInfoForm.phone = phone;
            this.changePhoneDialogConfig.showChangePhoneDialog = false;
            this.$refs[this.changePhoneDialogConfig.formRefName].resetFields();
            this.changePhoneDialogConfig.canClickSendBtn = true;
            this.$message.success('更换成功!')
          })
        },
        onCountDownFinish: () => {
          this.changePhoneDialogConfig.canClickSendBtn = true;
          this.changePhoneDialogConfig.sendCodeTipsText = '重新获取'
        }
      },
      uploadEnum,
      area: area.options,
      condition: {
        data: {
          business: []
        },
        clickedBusiness: [],
        onBusinessTagClick: (item) => {
          if (this.condition.clickedBusiness.includes(item)) {
            return;
          }
          let {shopInfoForm} = this;
          shopInfoForm.mainBusiness = (shopInfoForm.mainBusiness ? shopInfoForm.mainBusiness + ','
            : '') + item;
          this.condition.clickedBusiness.push(item);
        },
        loadCondition() {
          shop.showInfoCondition()
          .then(res => {
            let {business = []} = res.data;
            this.data.business = business;
          })
        },
      },
      preview: {
        logoFilePath: '',
        wxQrcode: '',
      },
      shopInfoForm: {
        name: null,
        principal: null,
        phone: null,
        qqNumber: null,
        wxNumber: null,
        wxQrcode: null,
        logo: null,
        area: null,
        addressDetail: null,
        introduce: null,
        mainBusiness: null,
        userPhone: null,
      },
      shopInfoFormRules: {
        name: [
          {required: true, message: '请输入店铺名称', trigger: ['blur', 'change']},
          {min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              this.$request.post({
                reqcode: '1081',
                reqdesc: '店铺名校验',
                shopName: value,
                shopId: this.shopInfoForm.id
              }).then(res => {
                const {data} = res;
                if (data) {
                  callback()
                } else {
                  callback(new Error('店铺名称已被使用'))
                }
              })
            },
            trigger: ['blur', 'change']
          }
        ],
        logo: [
          {required: true, message: '请上传店铺Logo', trigger: ['blur', 'change']}
        ],
        mainBusiness: [
          {required: true, message: '请输入主营业务', trigger: ['blur', 'change']}
        ],
        principal: [
          {required: true, message: '店铺负责人必填', trigger: ['blur', 'change']},
          {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: ['blur', 'change']}
        ],
        phone: [
          {required: true, message: '联系电话必填', trigger: ['blur', 'change']},
          {validator: asyncValidator.validPhone, message: '手机号格式不正确', trigger: ['blur', 'change']},
        ],
        qqNumber: [
          {required: true, message: '请输入qq号码', trigger: ['blur', 'change']},
          {validator: asyncValidator.validQQ, message: 'QQ格式不正确', trigger: ['blur', 'change']}
        ],
        wxNumber: [
          {required: true, message: '请输入微信号码', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
                if (value.length == 11 && !/^[A-Za-z]/.test(value)){
                  if (!/^1[3456789]\d{9}$/.test(value)){
                    callback(new Error('输入的手机号格式有误'));
                  }else {
                    callback()
                  }
                }else {
                  if (!/^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/.test(value)){
                    callback(new Error('微信号码不对'))
                  }
                }

            }
          }
        ],
        wxQrcode: [
          {required: true, message: '请上传微信二维码', trigger: ['blur', 'change']},
        ],
        area: [
          {required: true, message: '请选择经营地址', trigger: ['blur', 'change']}
        ],
        addressDetail: [
          {required: true, message: '请输入详细地址', trigger: ['blur', 'change']}
        ],
        introduce: [
          {required: true, message: '请输入店铺介绍', trigger: ['blur', 'change']}
        ]
      },
      uploadConfig: {
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'logo') {
            this.shopInfoForm.logo = response.src;
            this.preview.logoFilePath = response.src;
            this.$refs['shopInfoForm'].validateField('logo');
          } else if (key === 'wxQrcode') {
            this.$set(this.shopInfoForm, 'wxQrcode', response.src);
            this.preview.wxQrcode = response.src;
            this.$refs['shopInfoForm'].validateField('wxQrcode');
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'logo') {
            const allowType = [
              'image/png',
              'image/jpeg'
            ];
            const isImage =
              allowType.some(type => file.type.startsWith(type))
            ;
            const isLt10M = file.size / 1024 / 1024 < 1;
            if (!isImage) {
              this.$message.error('上传Logo只能是 图片 格式!')
            }
            if (!isLt10M) {
              this.$message.error('上传Logo大小不能超过1M')
            }
            result = isImage && isLt10M;
          } else if (key === 'wxQrcode') {
            const isLtSize = file.size / 1024 / 1024 < 1;
            const isImage = file.type.startsWith('image');
            if (!isImage) {
              this.$message.error('上传图片只能是 图片 格式!')
            }
            if (!isLtSize) {
              this.$message.error(`上传图片不能超过1M!`);
              return false;
            }
            result = isImage && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  watch: {
    'shopInfoForm.introduce'() {
      this.$refs['shopInfoForm'].validateField('introduce');
    },
    'shopInfoForm.phone': {
      handler(value) {
        if (validator.validPhone(value)) {
          // 手机号校验正确
        }
      }
    },
  },
  computed: {},
  beforeMount() {
    this.condition.loadCondition();
    this.getShopInfo();
  },
  methods: {
    getShopInfo() {
      shop.shopDetail({
        userId: this.user.userId
      }).then(res => {
        const {data} = res;
        if (data && Object.keys(data).length > 0) {
          const area = [];
          if (data.provinceCode) {
            area.push(Number(data.provinceCode));
          }
          if (data.cityCode) {
            area.push(Number(data.cityCode));
          }
          if (data.countyCode) {
            area.push(Number(data.countyCode));
          }
          this.shopInfoForm = {
            id: data.shopId,
            authFlag: data.authFlag,
            name: data.shopName || null,
            principal: data.principal || null,
            phone: data.phone || this.user.phone,
            logo: data.shopLogo || null,
            area: area.length > 0 ? area : null,
            addressDetail: data.addressDetail || null,
            introduce: data.introduce || null,
            mainBusiness: data.mainBusiness || null,
            qqNumber: data.qqCode,
            wxQrcode: data.wxQrcode,
            wxNumber: data.wxCode,
            userPhone: data.userPhone,
            qqAuth: data.qqCodeAuthFlag,
            wxAuth: data.wxCodeAuthFlag,
            wxAuthMemo: data.wxQrCodeAuthMemo,
            wxQrcodeAuth: data.wxQrCodeAuthFlag,
          };
          let reg = /(?:.*?)(\/upload\/.*?)$/
          if (data.shopLogo) {
            this.preview.logoFilePath = data.shopLogo
          }
          if (data.wxQrcode) {
            this.preview.wxQrcode = data.wxQrcode
          }
          this.$nextTick(this.$refs['shopInfoForm'].clearValidate)
        } else {
          this.shopInfoForm.phone = this.user.phone;
        }
      })
    },
    onSubmit(formName) {
      this.$refs[formName].validate()
      .then(() => {
        const shopInfo = this.shopInfoForm;
        shop.submitShopInfo({
          name: shopInfo.name,
          logo: shopInfo.logo.substring(shopInfo.logo.indexOf('/upload/')),
          area: shopInfo.area,
          address: shopInfo.addressDetail,
          userId: this.user.userId,
          principal: shopInfo.principal,
          tel: shopInfo.phone,
          introduce: shopInfo.introduce,
          mainBusiness: shopInfo.mainBusiness,
          qq: shopInfo.qqNumber,
          wx: shopInfo.wxNumber,
          wxQrcode: shopInfo.wxQrcode,
          code: shopInfo.code,
        }).then(res => {
          this.$message.success('提交成功！');
          this.$router.back();
        })
      });
    },
  }
}
</script>

<style scoped lang="scss">
.shop-info /deep/ {

  .el-card__header .title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 3px;
      background: $main-color;
      top: 0;
      bottom: 0;
      left: -10px;
    }
  }

  .shopInfoForm {
    .el-form-item:not(.auto) {
      width: 500px;
    }

    .auth_tips_wrap {
      position: absolute;
      top: 0;
      left: calc(100% + 10px);
      display: flex;
      align-items: center;
      height: 40px;
      width: 100%;

      i {
        margin-right: 5px;
      }
    }
  }

  .logo {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }
}


</style>
